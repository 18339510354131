/* Tailwind default breakpoints:
  'sm': '640px'
  'md': '768px'
  'lg': '1024px'
  'xl': '1280px'
  '2xl': '1536px'
  '3xl': '1920px' (custom) */

.feature-card {
    @apply transition duration-300 ease-in-out;
}

.feature-card:hover {
    @apply transform -translate-y-1;
}
.feature-title {
    @apply text-lg lg:text-2xl xl:text-[25px] mt-4 sm:mt-0 mb-2 text-primary;
}

.feature-description {
    @apply text-base lg:text-lg xl:text-[20px];
}

.feature-card-number {
    @apply text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-[35px] md:mb-3 border-t border-b border-primary py-3 lg:mb-6;
}

.features-grid {
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 md:gap-14 lg:gap-20;
}

.feature-services-title {
    @apply border-t border-b border-primary py-3 mb-3;
}

/* Add these new styles */
.rh-hero {
    background-size: cover;
}

@media (min-width: 1200px) {
    .rh-hero {
        background-size: 120% !important;
        animation: gradualMove 15s ease infinite;
    }
}

@keyframes gradualMove {
    0% {
        background-position: 20% 0%;
    }
    50% {
        background-position: 80% 60%;
    }
    100% {
        background-position: 20% 0%;
    }
}

.rh-searchbar-section {
    @apply w-full 
           bg-default-100
           py-6 sm:py-8 lg:py-10;
}

.rh-searchbar-container {
    @apply container 
           mx-auto 
           px-4 sm:px-6 lg:px-8 
           max-w-5xl;
}

.rh-searchbar-input {
    @apply w-full 
           py-3 sm:py-4 lg:py-5 
           px-6 sm:px-8 lg:px-10 
           pr-32 sm:pr-40 lg:pr-48 
           rounded-full 
           border-0 
           focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent 
           text-lg sm:text-xl lg:text-2xl
           bg-white;
}

.rh-searchbar-btn {
    @apply absolute 
           right-2 sm:right-4 lg:right-6 
           top-1/2 transform -translate-y-1/2 
           py-2 sm:py-2.5 lg:py-3 
           px-4 sm:px-6 lg:px-8 
           text-base sm:text-lg lg:text-xl 
           rounded-full 
           bg-black text-white 
           hover:bg-gray-900 
           transition duration-300;
}