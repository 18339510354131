/* Hind Font Faces */
@font-face {
    font-family: 'Hind';
    src: url('/fonts/Hind/Hind-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hind';
    src: url('/fonts/Hind/Hind-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hind';
    src: url('/fonts/Hind/Hind-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hind';
    src: url('/fonts/Hind/Hind-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hind';
    src: url('/fonts/Hind/Hind-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

/* Segoe UI Font Faces */
@font-face {
    font-family: 'Segoe UI';
    src: url('/fonts/SegoeUI/Segoe-UI.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('/fonts/SegoeUI/Segoe-UI-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('/fonts/SegoeUI/Segoe-UI-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('/fonts/SegoeUI/Segoe-UI-Bold-Italic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

/* Selawik Font Faces */
@font-face {
    font-family: 'Selawik';
    font-style: normal;
    font-weight: 400;
    src: local('Selawik'), url('/fonts/Selawik/selawk.woff') format('woff');
}

@font-face {
    font-family: 'Selawik';
    font-style: normal;
    font-weight: 300;
    src: local('Selawik Light'), url('/fonts/Selawik/selawkl.woff') format('woff');
}

@font-face {
    font-family: 'Selawik';
    font-style: normal;
    font-weight: 350;
    src: local('Selawik Semilight'), url('/fonts/Selawik/selawksl.woff') format('woff');
}

@font-face {
    font-family: 'Selawik';
    font-style: normal;
    font-weight: 600;
    src: local('Selawik Semibold'), url('/fonts/Selawik/selawksb.woff') format('woff');
}

@font-face {
    font-family: 'Selawik';
    font-style: normal;
    font-weight: 700;
    src: local('Selawik Bold'), url('/fonts/Selawik/selawkb.woff') format('woff');
}